import classes from './Contactinfo.module.scss';

const ContactInfo = () => {
  return (
    <div className={classes.contactInfo}>
      <div className={classes.infoBlock}>
        <div className={classes.infoBlockHeader}>
          <h3>Adres</h3>
        </div>
        <span>Barentszrede 2</span>
        <span>2725 GE Zoetermeer</span>
        <span>Nederland</span>
      </div>

      <div className={classes.infoBlock}>
        <div className={classes.infoBlockHeader}>
          <h3>E-mail</h3>
        </div>
        <span>info@tdrphotography.nl</span>
      </div>

      <div className={classes.infoBlock}>
        <div className={classes.infoBlockHeader}>
          <h3>Telefoon</h3>
        </div>
        <span>0646618315</span>
      </div>
    </div>
  );
};

export default ContactInfo;

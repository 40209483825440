import classes from './Header.module.scss';
import Logo from '../Logo/Logo';
import MenuIcon from '../MenuIcon/MenuIcon';

const Header = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.inner}>
        <Logo />
        <MenuIcon />
      </div>
    </header>
  );
};

export default Header;

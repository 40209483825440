import sanityClient from "../../client";
import { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import classes from "./Partners.module.scss";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Partners = () => {
  const [partnersData, setPartnersData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'partners']`)
      .then((data) => setPartnersData(data));
  }, []);

  if (!partnersData) return <div>Loading...</div>;

  return (
    <div className={classes.partners}>
      <h2>Partners</h2>
      <div className={classes.partnerLogos}>
        {partnersData.map((logo, index) => {
          return (
            <div className={classes.logoWrapper} key={index}>
              <img src={urlFor(logo.partner)} alt="" key={logo} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Partners;

import classes from './Footer.module.scss';
import { useLocation } from 'react-router';

const Footer = (props) => {
  const location = useLocation();

  return location.pathname !== '/' ? (
    <footer>
      <div className={classes.socialLinks}>
        <ul className={classes.socialList}>
          <li className={classes.socialListItem}>
            <a
              href="https://www.instagram.com/tdrphotography85/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href="https://www.facebook.com/www.tdrphotography.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li className={classes.socialListItem}>
            <a
              href="https://www.linkedin.com/in/tom-de-rooij-976a9945/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </div>

      <div className={classes.copyright}>
        <p>Copyright© 2021 by TDR Photography. Alle rechten voorbehouden.</p>
      </div>
    </footer>
  ) : null;
};

export default Footer;

import React, { useState, createContext } from "react";

export const StoreContext = createContext();

export const StoreProvider = (props) => {
  const [menuState, setMenuState] = useState({menuIsOpen: false});

  return (
    <StoreContext.Provider value={[menuState, setMenuState]}>
      {props.children}
    </StoreContext.Provider>
  );
};

import { Link } from 'react-router-dom';
import classes from './Logo.module.scss';
import logo from '../../assets/images/logo-tdr-zwart.png';

const Logo = () => {
  return (
    <div className={classes.logo}>
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
    </div>
  );
};

export default Logo;

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { StoreProvider } from '../Store';
import About from '../pages/About/About';
import Gallery from '../pages/Gallery/Gallery';
import Contact from '../pages/Contact/Contact';
import Home from '../pages/Home/Home';
import Header from '../components/Header/Header';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import Success from '../components/Success/Success';

import './App.scss';
import Weddings from '../pages/Weddings/Weddings';

const App = () => {
  return (
    <StoreProvider>
      <Router>
        <div className="App">
          <Navigation />
          <Header></Header>

          <Switch>
            <Route path="/" exact component={Home}></Route>
            <Route path="/about" component={About}></Route>
            <Route path="/weddings" component={Weddings}></Route>
            <Route path="/gallery" component={Gallery}></Route>
            <Route path="/contact" component={Contact}></Route>
            <Route path="/success" component={Success}></Route>
          </Switch>
        </div>

        <Footer />
      </Router>
    </StoreProvider>
  );
};

export default App;

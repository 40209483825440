import classes from './Loading.module.scss';

const Loading = (props) => {
  return (
    <div className={classes.loadingWrapper}>
      <div className={classes.load}>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
      </div>

      <h3 className={classes.message}>{props.message}</h3>
    </div>
  );
};

export default Loading;

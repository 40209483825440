import classes from './Success.module.scss';
import { NavLink } from 'react-router-dom';

const Success = () => {
  return (
    <div className={classes.success}>
      <div className="container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={classes.checkmark}
          viewBox="0 0 512 512"
          fill="blue`"
        >
          <path
            d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
            fill="white"
            stroke="green"
            stroke-miterlimit="10"
            stroke-width="32"
          />
          <path
            fill="none"
            stroke="green"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="32"
            d="M352 176L217.6 336 160 272"
          />
        </svg>

        <h1>Bedankt voor je bericht! </h1>
        <p>We nemen zo snel mogelijk contact met je op</p>

        <NavLink to="/contact" className={classes.btn}>
          Breng me terug
        </NavLink>
      </div>
    </div>
  );
};

export default Success;

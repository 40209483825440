import sanityClient from '../../client';
import { useEffect, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import classes from './Weddings.module.scss';
import Loading from '../../components/Loading/Loading';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Weddings = () => {
  const [weddingsData, setWeddingsData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'weddings']`)
      .then((data) => setWeddingsData(data[0]))
      .catch(console.error);
  }, []);

  if (!weddingsData)
    return (
      <div>
        <Loading />
      </div>
    );

  const {
    pageTitle,
    firstTextBlock,
    firstImageBlock,
    secondTextBlock,
    secondImageBlock,
    thirdTextBlock,
  } = weddingsData;

  return (
    <div className={classes.Wedding}>
      <header>
        <h1>{pageTitle}</h1>
      </header>
      <main>
        <section className={classes.textBlockFirst}>
          <h2>{firstTextBlock?.title}</h2>
          <p>{firstTextBlock?.text}</p>
        </section>

        {firstImageBlock ? (
          <section className={classes.images}>
            <img src={urlFor(firstImageBlock?.leftImage)} alt="" />
            <img src={urlFor(firstImageBlock?.centerImage)} alt="" />
            <img src={urlFor(firstImageBlock?.rightImage)} alt="" />
          </section>
        ) : null}

        <section className={classes.textBlockFirst}>
          <h2>{secondTextBlock?.title}</h2>
          <p>{secondTextBlock?.text}</p>
        </section>

        {secondImageBlock ? (
          <section className={classes.images}>
            <img src={urlFor(secondImageBlock?.leftImage)} alt="" />
            <img src={urlFor(secondImageBlock?.centerImage)} alt="" />
            <img src={urlFor(secondImageBlock?.rightImage)} alt="" />
          </section>
        ) : null}

        <section className={classes.textBlockFirst}>
          <h2>{thirdTextBlock?.title}</h2>
          <p>{thirdTextBlock?.text}</p>
        </section>
      </main>
    </div>
  );
};

export default Weddings;

import sanityClient from '../../../client';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Navlist.module.scss';
import { StoreContext } from '../../../Store';
import { useEffect, useState } from 'react';

const Navlist = () => {
  const [menuState, setMenuState] = useContext(StoreContext);
  const [hasWeddingsData, setHasWeddingsData] = useState(false);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'weddings']`)
      .then((data) => {
        if (data[0].firstTextBlock) {
          setHasWeddingsData(true);
        }
      })
      .catch(console.error);
  }, []);

  const closeMenuHandler = () => {
    setMenuState({ menuIsOpen: false });
  };

  return (
    <nav className={classes.nav}>
      <ul className={classes.navList}>
        <li className={classes.navLink}>
          <NavLink
            exact
            to="/"
            activeClassName="activeLink"
            onClick={closeMenuHandler}
          >
            Home
          </NavLink>
        </li>

        <li className={classes.navLink}>
          <NavLink
            exact
            to="/about"
            activeClassName="activeLink"
            onClick={closeMenuHandler}
          >
            Over TDR
          </NavLink>
        </li>

        {hasWeddingsData ? (
          <li className={classes.navLink}>
            <NavLink
              exact
              to="/weddings"
              activeClassName="activeLink"
              onClick={closeMenuHandler}
            >
              Weddings
            </NavLink>
          </li>
        ) : null}

        <li className={classes.navLink}>
          <NavLink
            to="/gallery/horeca"
            activeClassName="activeLink"
            onClick={closeMenuHandler}
          >
            Galerij
          </NavLink>
        </li>

        <li className={classes.navLink}>
          <NavLink
            exact
            to="/contact"
            activeClassName="activeLink"
            onClick={closeMenuHandler}
          >
            Stel een vraag
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navlist;

import React, { useContext } from 'react';
import NavList from './Navlist/Navlist';
import ContactInfo from './Contactinfo/Contactinfo';
import classes from './Navigation.module.scss';
import Socials from '../Socials/Socials';

import { StoreContext } from '../../Store';

const Navigation = () => {
  const [menuState] = useContext(StoreContext);
  const assignedClasses = [classes.navigation];

  if (menuState.menuIsOpen) {
    assignedClasses.push(classes.open);
    document.body.classList.add('overflow');
  } else {
    if (document.body.classList.contains('overflow')) {
      document.body.classList.remove('overflow');
    }
  }

  return (
    <div className={assignedClasses.join(' ')}>
      <div className={classes.wrapper}>
        <NavList />
        <Socials />
        <ContactInfo />
      </div>
    </div>
  );
};

export default Navigation;

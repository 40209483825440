import sanityClient from '../../client';
import { useEffect, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { NavLink } from 'react-router-dom';

import classes from './About.module.scss';

// import video from '../../assets/videos/video-about.mp4';
import Partners from '../../components/Partners/Partners';
import Loading from '../../components/Loading/Loading';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const About = () => {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'about']`)
      .then((data) => setAboutData(data[0]))
      .catch(console.error);
  }, []);

  if (!aboutData)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className={classes.About}>
      <header>
        <img
          src={urlFor(aboutData.headerImage)}
          alt=""
          className={classes.headerImage}
        />
        <div className={classes.overlay}></div>
        <h1>{aboutData.mainTitle}</h1>
      </header>

      <main>
        <section className={classes.infoFirst}>
          <h2>{aboutData.introTitle}</h2>
          <p>{aboutData.introBody}</p>
        </section>

        <section className={classes.images}>
          <img src={urlFor(aboutData.leftImage)} alt="" />
          <img src={urlFor(aboutData.centerImage)} alt="" />
          <img src={urlFor(aboutData.rightImage)} alt="" />
        </section>

        <section className={classes.infoSecond}>
          <p>{aboutData.contentNextToQuote}</p>
        </section>

        {urlFor(aboutData.fullWidthImage) ? (
          <section className={classes.imageLarge}>
            <img src={urlFor(aboutData.fullWidthImage)} alt="" />
          </section>
        ) : null}

        <section className={classes.partners}>
          <Partners />
        </section>

        <section className={classes.price}>
          <h2>Tarief</h2>
          <p>{aboutData.price}</p>

          <NavLink to="/contact" className={classes.btn}>
            Contact
          </NavLink>
        </section>
      </main>
    </div>
  );
};

export default About;

import styles from './Home.module.scss';
import sanityClient from '../../client';
import { useEffect, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import Loading from '../../components/Loading/Loading';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

function Homepage() {
  const [homeData, setHomeData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'home']`)
      .then((data) => {
        return setHomeData(data[0]);
      })
      .catch(console.error);
  }, []);

  if (!homeData)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className={styles.Home}>
      <div>
        <img
          src={urlFor(homeData.backgroundImageMobile)}
          className="mobile"
          alt=""
        />
        <img
          src={urlFor(homeData.backgroundImageDesktop)}
          className="desktop"
          alt=""
        />
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
}

export default Homepage;

import {
  // BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
} from 'react-router-dom';
import sanityClient from '../../client';
import { useEffect, useState } from 'react';

import GalleryGrid from '../../components/GalleryGrid/GalleryGrid';

import classes from './Gallery.module.scss';

const Gallery = () => {
  let { url } = useRouteMatch();
  const [categoriesData, setCategoriesData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'gallery']{
        category
      }`
      )
      .then((data) => {
        const categoriesArr = [];

        for (const category in data) {
          const singleItem = data[category];

          if (!categoriesArr.includes(singleItem.category)) {
            categoriesArr.push(singleItem.category);
          }
        }

        return setCategoriesData(categoriesArr);
      })
      .catch(console.error);
  }, []);

  if (!categoriesData) return <div>Loading...</div>;

  return (
    <div className={classes.Gallery}>
      <div className="container">
        <div className={classes.nav}>
          <ul className={classes.navList}>
            {/* <li className={classes.navLink}>
              <NavLink
                className={classes.navLinkInner}
                exact
                to={url}
                activeClassName="activeGalleryLink"
              >
                All
              </NavLink>
            </li> */}
            {categoriesData.map((category, index) => {
              return (
                <li className={classes.navLink} key={category}>
                  <NavLink
                    className={classes.navLinkInner}
                    exact
                    to={`/gallery/${category.toLowerCase()}`}
                    activeClassName="activeGalleryLink"
                    key={index}
                  >
                    {category}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={classes.grid}>
          <Switch>
            <Route
              path={url}
              exact
              component={() => <GalleryGrid />}
              category="all"
            ></Route>

            {categoriesData.map((category, index) => {
              return (
                <Route
                  path={`${url}/${category.toLowerCase()}`}
                  key={index}
                  component={() => <GalleryGrid category={category} />}
                ></Route>
              );
            })}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

import React from 'react';
import classes from './Socials.module.scss';

import { ReactComponent as FacebookLogo } from '../../assets/icons/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../assets/icons/instagram.svg';
import { ReactComponent as LinkedinLogo } from '../../assets/icons/linkedin.svg';

const Socials = () => {
  return (
    <div className={classes.socials}>
      <a
        href="https://www.instagram.com/tdrphotography85/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramLogo />
      </a>

      <a
        href="https://www.facebook.com/www.tdrphotography.nl/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookLogo />
      </a>

      <a
        href="https://www.linkedin.com/in/tom-de-rooij-976a9945/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedinLogo />
      </a>
    </div>
  );
};

export default Socials;

import React, { useContext } from 'react';
import classes from './MenuIcon.module.scss';
import { StoreContext } from '../../Store';

const HamburgerMenuIcon = () => {
  const [menuState, setMenuState] = useContext(StoreContext);
  const assignedClasses = [classes.menuIcon];

  const toggleMenu = () => {
    const newState = !menuState.menuIsOpen;
    setMenuState({ menuIsOpen: newState });
  };

  if (menuState.menuIsOpen) {
    assignedClasses.push(classes.openMenu);
  }

  return (
    <div className={assignedClasses.join(' ')} onClick={toggleMenu}>
      <div className={classes.iconWrapper}>
        <span className={classes.line}></span>
        <span className={classes.line}></span>
        <span className={classes.line}></span>
      </div>
      <span className={classes.text}>menu</span>
    </div>
  );
};

export default HamburgerMenuIcon;

import classes from './Contact.module.scss';

const Contact = () => {
  return (
    <div className={classes.Contact}>
      <div className="container">
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <h1 className={classes.contentTitle}>Let's talk!</h1>
            <p className={classes.paragraph}>
              Wilt u graag meer informatie over de mogelijkheden. Stuur een
              bericht en dan wordt er zo snel mogelijk contact met u opgenomen.
            </p>

            <div className={classes.contact}>
              <h3>TDR Photography</h3>
              <div className={classes.contactInfo}>
                <div className={classes.address}>Barentszrede 2</div>
                <div className={classes.address}>2725 GE Zoetermeer</div>
                <div className={classes.phone}>0646618315</div>
                <div className={classes.email}>info@tdrphotography.nl</div>
              </div>
            </div>
          </div>

          <div className={classes.formWrapper}>
            <form
              action="https://formsubmit.co/info@tdrphotography.nl"
              method="POST"
            >
              <input type="text" name="naam" placeholder="Naam*" required />
              <input type="email" name="email" placeholder="Email*" required />
              <input
                type="number"
                name="telefoonnummer"
                placeholder="Telefoonnummer*"
                required
              />
              <textarea
                name="message"
                cols="30"
                rows="0"
                placeholder="Bericht*"
                required
              ></textarea>

              <input type="submit" value="Verstuur" />

              <input
                type="hidden"
                name="_next"
                value="https://tdrphotography.nl/success"
              />
              <input type="hidden" name="_subject" value="New submission!" />
              <input type="text" name="_honey" style={{ display: 'none' }} />
              <input type="hidden" name="_captcha" value="false"></input>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

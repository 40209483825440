import sanityClient from '../../client';
import { useEffect, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import classes from './GalleryGrid.module.scss';
import Loading from '../Loading/Loading';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const GalleryGrid = (props) => {
  const [images, setImages] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'gallery' ${
          props.category ? `&& category == '${props.category}'` : ''
        } ] {
        image
      }`
      )
      .then((data) => setImages(data[0].image))
      .catch(console.error);
  }, [props.category]);

  if (!images)
    return (
      <div>
        <Loading message="Loading images" />
      </div>
    );

  return (
    <div className={classes.GalleryGrid}>
      {images.map((image, index) => {
        return (
          <div className={classes.gridItem} key={index}>
            <img src={urlFor(image)} alt="" />
          </div>
        );
      })}
    </div>
  );
};

export default GalleryGrid;
